import Card from "./Card";
import card1  from "../assets/images/card1.png";
import card2  from "../assets/images/card2.png";
import card3  from "../assets/images/card3.png";


function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">  
                    <div className="col-md-4 mb-2">
                        <Card title="Business Devlopment [AI]" img={card1} text="Develop AI-powered chatbots and virtual assistants for websites, customer support, and business processes to enhance user experiences and streamline operations " />
                    </div>
                    <div className="col-md-4 mb-2">
                        <Card title="Digital Experience" img={card1} text="Create responsive, user-friendly websites and web applications with modern design aesthetics, ensuring a seamless user experience across various devices " />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Application Development" img={card2} text="Build mobile apps for iOS and Android platforms that cater to specific business needs, enhancing customer engagement and expanding your clients' reach" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-2">
                    <Card title="Automation" img={card3} text="Through our services, we offer top-notch software solutions that harness the capabilities of AI and IoT to seamlessly automate and enhance essential business processes, driving efficiency and fostering innovation" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Tailored Solutions" img={card3} text="Develop tailor-made software solutions that address unique business challenges and automate complex processes, increasing efficiency and reducing manual effort" />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Business Data Intelligences" img={card3} text="Provide insights through data analysis, visualization, and reporting tools to help clients make informed decisions and optimize their operations" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
